<!-- 市场资讯详情 -->
<template>
<div class="newsList">
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <!--新闻列表-->
    <div class="wCenter" v-if="config.c1&&config.c1.isShow">
        <div class="newDetail">
            <div class="newDetailItem">
                <p class="title">{{title}}</p>
                <p class="time">{{time}}</p>
                <p class="content" v-html="content"></p>
            </div>
        </div>
    </div>
    <!--新闻列表-->
</div>
</template>

<script>
import {
    getPageConfigByCode,
    getInformationDetail
} from '@/api/api'
import TopImg from '@/components/topImg'
export default {
    name: 'newsDetail',
    components: {
        TopImg,
    },
    data() {
        return {
            config: {},
            title: '',
            content: '',
            time: ''
        }
    },
    computed: {},
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'newsDetail/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                    let c1 = this.config.c1
                    if (c1 && c1.isShow) {
                        this.getInformationDetail();
                    }
                }
            })
        },
        getInformationDetail() {
            getInformationDetail(this.$route.query.id).then(res => {
                if (res.success&&res.result) {
                    this.title = res.result.title
                    this.content = res.result.content
                    this.time = res.result.pushTime
                    this.$route.meta.title=res.result.seoTitle
                    this.$route.meta.keywords=res.result.seoKeyword
                    this.$route.meta.description=res.result.seoDesc   
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.newDetail {
    .newDetailItem {
        margin-top: 0.3rem;

        .title {
            color: #333333;
            font-size: 0.24rem;
            border-left: 0.07rem solid #ff1932;
            padding-left: 0.25rem;
            margin-top: 0.18rem;
			@media (max-width: 767px) {
			     font-size: 0.4rem;
			}
			
        }

        .time {
            color: #aaaaaa;
            font-size: 0.2rem;
            margin: 0.26rem 0;
            padding-left: 0.34rem;
        }

        .content {
            color: #333333;
            font-size: 0.2rem;
            text-align: justify;
            line-height: 2;
			
            img{
                max-width: 100%!important;
            }
			@media (max-width: 767px) {
			     font-size: 0.38rem;
				
			}
        }
		
        .pic {
            text-align: center;
            margin: 0.7rem 0 0.2rem 0;

            @media (max-width: 767px) {
                margin: 0.2rem 0 0.3rem 0;
            }

            img {
                width: 90%;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
}
</style>
<style lang="scss">
	@media (max-width: 767px) {
	    .content img{
	    	width: 100%!important;
	    }
		
	}
	
	
</style>